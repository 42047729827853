import React from 'react';
import { Redirect, Router } from '@reach/router';
import { useIntl } from 'gatsby-plugin-intl';
import useCookie from "react-use/esm/useCookie";
import useEffectOnce from "react-use/esm/useEffectOnce";
import { ArtworkCheckout } from '@/checkout/components/artwork-checkout';
import { useCheckout } from '@/checkout/components/hooks';
import { CheckoutStatus } from '@/checkout/store';
import { useUri } from '@/common/hooks';
import { getCookieExpiration } from '@/common/utils/cookie-helper';
import NotFoundPage from '@/pages/404';
import { AuthenticatedRoute } from '@/profile/routes/authenticated-route';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "507206203";

var ArtworkCheckoutPage = function ArtworkCheckoutPage(_ref) {
  var data = _ref.data,
      pageContext = _ref.pageContext;

  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  var _useCheckout = useCheckout(),
      setPendingCheckout = _useCheckout.setPendingCheckout;

  var _useCookie = useCookie('affiliateCode'),
      affiliateCookie = _useCookie[0],
      updateAffiliateCookie = _useCookie[1];

  useEffectOnce(function () {
    if (data.moonaapi.artwork) {
      setPendingCheckout(data.moonaapi.artwork.id, CheckoutStatus.New, data.moonaapi.artwork.slug);
    }

    if (typeof window !== 'undefined') {
      var urlParams = new URLSearchParams(window.location.search);
      var affiliateCode = urlParams.get('affiliateCode');

      if (affiliateCode) {
        updateAffiliateCookie(affiliateCode, {
          expires: getCookieExpiration()
        });
      } else {
        if (affiliateCookie) {
          var url = new URL(window.location.href);
          url.searchParams.set('affiliateCode', affiliateCookie);
          window.history.replaceState({}, '', url.toString());
        }
      }
    }
  });

  if (!data.moonaapi.artwork) {
    return ___EmotionJSX(Redirect, {
      to: "/404",
      noThrow: true
    });
  }

  return ___EmotionJSX(Router, {
    basepath: "/" + locale + uriHelper.getArtworkCheckoutUrl(data.moonaapi.artwork.slug)
  }, ___EmotionJSX(ArtworkCheckout, {
    path: "/",
    artworkId: data.moonaapi.artwork.id
  }), ___EmotionJSX(AuthenticatedRoute, {
    component: ArtworkCheckout,
    artworkId: data.moonaapi.artwork.id,
    path: "/success/"
  }), ___EmotionJSX(NotFoundPage, {
    default: true
  }));
};

ArtworkCheckoutPage.displayName = "ArtworkCheckoutPage";
export { ArtworkCheckoutPage as default, query };