import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useCheckout } from '@/checkout/components/hooks/checkout-hook';
import { useUserError } from '@/common/hooks';
import { injected } from '@/common/utils/crypto/connectors';

function useCheckoutWithCrypto() {
  var _useUserError = useUserError(),
      showUserErrors = _useUserError.showUserErrors,
      showApolloErrors = _useUserError.showApolloErrors;

  var _useCheckout = useCheckout(),
      setCheckoutComplete = _useCheckout.setCheckoutComplete;

  var isValidInitCryptoCheckoutResponse = useCallback(function (artworkId, checkoutDate, checkoutErrors) {
    var _checkoutDate$createE, _checkoutDate$createE2;

    setCheckoutComplete(false);

    if (checkoutDate !== null && checkoutDate !== void 0 && (_checkoutDate$createE = checkoutDate.createEditionCryptoPayment) !== null && _checkoutDate$createE !== void 0 && (_checkoutDate$createE2 = _checkoutDate$createE.userErrors) !== null && _checkoutDate$createE2 !== void 0 && _checkoutDate$createE2.length) {
      var _checkoutDate$createE3;

      showUserErrors(checkoutDate === null || checkoutDate === void 0 ? void 0 : (_checkoutDate$createE3 = checkoutDate.createEditionCryptoPayment) === null || _checkoutDate$createE3 === void 0 ? void 0 : _checkoutDate$createE3.userErrors, artworkId);
      return false;
    }

    if (checkoutErrors !== null && checkoutErrors !== void 0 && checkoutErrors.length) {
      showApolloErrors({
        graphQLErrors: checkoutErrors
      });
      return false;
    }

    return true;
  }, [setCheckoutComplete, showApolloErrors, showUserErrors]);
  return {
    isValidInitCryptoCheckoutResponse: isValidInitCryptoCheckoutResponse
  };
}

function useInactiveListener(suppress) {
  if (suppress === void 0) {
    suppress = false;
  }

  var _useWeb3React = useWeb3React(),
      active = _useWeb3React.active,
      error = _useWeb3React.error,
      activate = _useWeb3React.activate;

  useEffect(function () {
    var _ref = window,
        ethereum = _ref.ethereum;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      var handleConnect = function handleConnect() {
        activate(injected);
      };

      var handleChainChanged = function handleChainChanged(chainId) {
        activate(injected);
      };

      var handleAccountsChanged = function handleAccountsChanged(accounts) {
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      return function () {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}

function useEagerConnect() {
  var _useWeb3React2 = useWeb3React(),
      activate = _useWeb3React2.activate,
      active = _useWeb3React2.active;

  var _useState = useState(false),
      tried = _useState[0],
      setTried = _useState[1];

  useEffect(function () {
    injected.isAuthorized().then(function (isAuthorized) {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(function () {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))
  // if the connection worked, wait until we get confirmation of that to flip the flag

  useEffect(function () {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);
  return tried;
}

export { useCheckoutWithCrypto, useInactiveListener, useEagerConnect };