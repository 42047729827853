import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useWallet } from '@/common/hooks';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Base = _styled("div", {
  target: "e1nvrwux0",
  label: "wallet-balance_Base"
})(process.env.NODE_ENV === "production" ? {
  name: "k008qs",
  styles: "display:flex;"
} : {
  name: "k008qs",
  styles: "display:flex;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by93YWxsZXQtYmFsYW5jZS50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBT3VCIiwiZmlsZSI6Ii9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by93YWxsZXQtYmFsYW5jZS50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCc7XG5pbXBvcnQgUmVhY3QsIHsgQ1NTUHJvcGVydGllcyB9IGZyb20gJ3JlYWN0JztcblxuaW1wb3J0IHsgRm9ybWF0dGVkTWVzc2FnZSB9IGZyb20gJ2dhdHNieS1wbHVnaW4taW50bCc7XG5cbmltcG9ydCB7IHVzZVdhbGxldCB9IGZyb20gJ0AvY29tbW9uL2hvb2tzJztcblxuY29uc3QgQmFzZSA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG5gO1xuXG5pbnRlcmZhY2UgV2FsbGV0QmFsYW5jZVByb3BzIHtcbiAgc3R5bGU/OiBDU1NQcm9wZXJ0aWVzO1xuICBjbGFzc05hbWU/OiBzdHJpbmc7XG59XG5cbmNvbnN0IFdhbGxldEJhbGFuY2U6IFJlYWN0LkZDPFdhbGxldEJhbGFuY2VQcm9wcz4gPSAoeyBzdHlsZSwgY2xhc3NOYW1lIH0pID0+IHtcbiAgY29uc3QgeyBiYWxhbmNlIH0gPSB1c2VXYWxsZXQoKTtcblxuICByZXR1cm4gKFxuICAgIDxCYXNlIHN0eWxlPXtzdHlsZX0gY2xhc3NOYW1lPXtjbGFzc05hbWV9PlxuICAgICAgPEZvcm1hdHRlZE1lc3NhZ2UgaWQ9XCJ3YWxsZXQuYmFsYW5jZVwiIC8+eycgJ31cbiAgICAgIHtiYWxhbmNlID09PSBudWxsID8gJy0nIDogYM6eJHtwYXJzZUZsb2F0KGJhbGFuY2UpLnRvRml4ZWQoNCl9YH1cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBXYWxsZXRCYWxhbmNlIH07XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var WalletBalance = function WalletBalance(_ref) {
  var style = _ref.style,
      className = _ref.className;

  var _useWallet = useWallet(),
      balance = _useWallet.balance;

  return ___EmotionJSX(Base, {
    style: style,
    className: className
  }, ___EmotionJSX(FormattedMessage, {
    id: "wallet.balance"
  }), ' ', balance === null ? '-' : "\u039E" + parseFloat(balance).toFixed(4));
};

WalletBalance.displayName = "WalletBalance";
export { WalletBalance };