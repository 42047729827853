import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useCheckout } from '@/checkout/components/hooks/checkout-hook';
import { useAuthenticatedMutation, useUserError } from '@/common/hooks';
var initiateCheckoutMutation = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  mutation InitiateCheckoutMutation(\n    $artworkId: ID!\n    $quantity: PositiveInt!\n    $affiliateCode: String\n  ) {\n    initiateEditionCheckout(\n      input: {\n        lineItems: [{ artworkId: $artworkId, quantity: $quantity }]\n        affiliateCode: $affiliateCode\n      }\n    ) {\n      checkout {\n        lineItems {\n          artwork {\n            id\n            title\n            purchasePrice {\n              amount\n            }\n            artistProfile {\n              artistName\n            }\n          }\n          editionNumbers\n          quantity\n        }\n        completionDateTime\n        creationDateTime\n        expirationDateTime\n        id\n      }\n      defaultCard {\n        expirationYearMonth\n        lastDigits\n      }\n      userErrors {\n        code\n        field\n        logCode\n        message\n        isRetryable\n      }\n    }\n  }\n"])));
var completeEditionCheckoutMutation = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  mutation CompleteEditionCheckoutMutation($checkoutId: ID!) {\n    completeEditionCheckout(input: { checkoutId: $checkoutId }) {\n      checkout {\n        lineItems {\n          artwork {\n            id\n            title\n          }\n          editionNumbers\n          quantity\n        }\n        completionDateTime\n        creationDateTime\n        expirationDateTime\n        id\n      }\n      userErrors {\n        code\n        field\n        logCode\n        message\n        isRetryable\n      }\n    }\n  }\n"])));
var createEditionCheckoutSessionMutation = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  mutation CreateEditionCheckoutSessionMutation($checkoutId: ID!) {\n    createEditionCheckoutSession(input: { checkoutId: $checkoutId }) {\n      checkout {\n        lineItems {\n          artwork {\n            id\n            title\n          }\n          editionNumbers\n          quantity\n        }\n        completionDateTime\n        creationDateTime\n        expirationDateTime\n        id\n      }\n      sessionId\n      stripeAccount\n      userErrors {\n        code\n        field\n        logCode\n        message\n        isRetryable\n      }\n    }\n  }\n"])));
var createEditionCryptoPaymentMutation = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  mutation CreateEditionCryptoPayment(\n    $checkoutId: ID!\n    $buyerPublicKey: EthereumPublicKey\n  ) {\n    createEditionCryptoPayment(\n      input: { checkoutId: $checkoutId, buyerPublicKey: $buyerPublicKey }\n    ) {\n      checkout {\n        coreId\n        expirationDateTime\n      }\n      artistId\n      artworkId\n      buyerPublicKey\n      tokenId\n      dloopPublicKey\n      dloopAmount {\n        amount\n      }\n      artistPublicKey\n      artistAmount {\n        amount\n      }\n      editionPrice {\n        formattedValue\n      }\n      maxSendableAmount {\n        amount\n      }\n      formattedSlippagePercentage\n      slippage {\n        formattedValue\n      }\n      totalPrice {\n        amount\n        formattedValue\n      }\n      gasPrice {\n        amount\n      }\n      gasLimit {\n        amount\n      }\n      smartContractExpirationDateTime\n      userInterfaceExpirationDateTime\n      signature\n      paymentContractAddress\n      userErrors {\n        code\n        field\n        logCode\n        message\n        isRetryable\n      }\n    }\n  }\n"])));
var stripePublishableKeyQuery = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  query StripePublishableKeyQuery {\n    config {\n      webApp {\n        ... on WebAppConfig {\n          v1(revision: REVISION_1)\n        }\n      }\n    }\n  }\n"])));

function useCheckoutProcess() {
  var _useUserError = useUserError(),
      showUserErrors = _useUserError.showUserErrors,
      showApolloErrors = _useUserError.showApolloErrors;

  var _useCheckout = useCheckout(),
      setCheckoutComplete = _useCheckout.setCheckoutComplete;

  var _useAuthenticatedMuta = useAuthenticatedMutation(initiateCheckoutMutation),
      initiateCheckout = _useAuthenticatedMuta[0],
      initiateCheckoutLoading = _useAuthenticatedMuta[1].loading;

  var _useAuthenticatedMuta2 = useAuthenticatedMutation(completeEditionCheckoutMutation),
      completeCheckout = _useAuthenticatedMuta2[0],
      completeCheckoutLoading = _useAuthenticatedMuta2[1].loading;

  var _useAuthenticatedMuta3 = useAuthenticatedMutation(createEditionCheckoutSessionMutation),
      completePaidCheckout = _useAuthenticatedMuta3[0],
      completePaidCheckoutLoading = _useAuthenticatedMuta3[1].loading;

  var _useAuthenticatedMuta4 = useAuthenticatedMutation(createEditionCryptoPaymentMutation),
      createEditionCryptoPayment = _useAuthenticatedMuta4[0],
      createEditionCryptoPaymentLoading = _useAuthenticatedMuta4[1].loading;

  var _useQuery = useQuery(stripePublishableKeyQuery),
      stripePublishableKey = _useQuery.data,
      stripePublishableKeyLoading = _useQuery.loading;

  var isValidInitCheckoutResponse = useCallback(function (artworkId, initData, initiateErrors) {
    var _initData$initiateEdi, _initData$initiateEdi2, _initData$initiateEdi3;

    setCheckoutComplete(false);

    if (initData !== null && initData !== void 0 && (_initData$initiateEdi = initData.initiateEditionCheckout) !== null && _initData$initiateEdi !== void 0 && (_initData$initiateEdi2 = _initData$initiateEdi.userErrors) !== null && _initData$initiateEdi2 !== void 0 && _initData$initiateEdi2.length) {
      showUserErrors(initData.initiateEditionCheckout.userErrors, artworkId);
      return false;
    }

    if (!(initData !== null && initData !== void 0 && (_initData$initiateEdi3 = initData.initiateEditionCheckout) !== null && _initData$initiateEdi3 !== void 0 && _initData$initiateEdi3.checkout) || initiateErrors !== null && initiateErrors !== void 0 && initiateErrors.length) {
      initiateErrors && showApolloErrors(initiateErrors);
      return;
    }

    return true;
  }, [setCheckoutComplete, showApolloErrors, showUserErrors]);
  return {
    initiateCheckout: initiateCheckout,
    initiateCheckoutLoading: initiateCheckoutLoading,
    isValidInitCheckoutResponse: isValidInitCheckoutResponse,
    completeCheckout: completeCheckout,
    completeCheckoutLoading: completeCheckoutLoading,
    completePaidCheckout: completePaidCheckout,
    completePaidCheckoutLoading: completePaidCheckoutLoading,
    stripePublishableKey: stripePublishableKey,
    stripePublishableKeyLoading: stripePublishableKeyLoading,
    createEditionCryptoPayment: createEditionCryptoPayment,
    createEditionCryptoPaymentLoading: createEditionCryptoPaymentLoading
  };
}

export { useCheckoutProcess };