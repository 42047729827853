import { useCallback, useEffect, useState } from 'react';
import { formatEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import useInterval from "react-use/esm/useInterval";

function useWallet() {
  var _useWeb3React = useWeb3React(),
      account = _useWeb3React.account,
      library = _useWeb3React.library,
      chainId = _useWeb3React.chainId;

  var _useState = useState(null),
      balance = _useState[0],
      setBalance = _useState[1];

  useInterval(function () {
    getBalance();
  }, 2000);
  var getBalance = useCallback(function () {
    if (!!account && !!library) {
      return library.getBalance(account).then(function (res) {
        setBalance(formatEther(res));
      }).catch(function () {
        setBalance(null);
      });
    }

    return function () {
      setBalance(null);
    };
  }, [account, library]);
  useEffect(function () {
    getBalance();
  }, [account, library, chainId, getBalance]);
  return {
    balance: balance
  };
}

export { useWallet };