import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
var pendingArtworkCheckoutQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query PendingArtworkCheckoutQuery($id: ID!) {\n    artwork: node(id: $id) {\n      ... on Artwork {\n        id\n        title\n        slug\n        thumbnailUrl\n        editionAvailableCount\n        editionSalesCount\n        editionPendingCount\n        editionSize\n        purchasePrice {\n          amount\n          formattedValue\n        }\n        artist {\n          firstName\n          lastName\n        }\n        artistProfile {\n          artistName\n          paymentMethods {\n            isAcceptingAny\n            isAcceptingCreditCard\n            isAcceptingCryptocurrency\n          }\n        }\n      }\n    }\n  }\n"])));

function useCheckoutArtwork(artworkId) {
  var _useQuery = useQuery(pendingArtworkCheckoutQuery, {
    variables: {
      id: artworkId
    },
    skip: !artworkId
  }),
      pendingArtworkCheckoutData = _useQuery.data;

  return {
    pendingArtworkCheckoutData: pendingArtworkCheckoutData
  };
}

export { useCheckoutArtwork };