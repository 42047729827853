import _styled from "@emotion/styled-base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { WalletAccount } from '@/common/components/crypto/wallet-account';
import { WalletBalance } from '@/common/components/crypto/wallet-balance';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Base = _styled("div", {
  target: "eyv1hij0",
  label: "selected-wallet_Base"
})(process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by9zZWxlY3RlZC13YWxsZXQudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVF1QiIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy9jcnlwdG8vc2VsZWN0ZWQtd2FsbGV0LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcbmltcG9ydCBSZWFjdCwgeyBDU1NQcm9wZXJ0aWVzIH0gZnJvbSAncmVhY3QnO1xuXG5pbXBvcnQgeyBGb3JtYXR0ZWRNZXNzYWdlIH0gZnJvbSAnZ2F0c2J5LXBsdWdpbi1pbnRsJztcblxuaW1wb3J0IHsgV2FsbGV0QWNjb3VudCB9IGZyb20gJ0AvY29tbW9uL2NvbXBvbmVudHMvY3J5cHRvL3dhbGxldC1hY2NvdW50JztcbmltcG9ydCB7IFdhbGxldEJhbGFuY2UgfSBmcm9tICdAL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by93YWxsZXQtYmFsYW5jZSc7XG5cbmNvbnN0IEJhc2UgPSBzdHlsZWQuZGl2YGA7XG5cbmNvbnN0IEluZm9TZWN0aW9uVGl0bGUgPSBzdHlsZWQuaDNgXG4gIG1hcmdpbjogMDtcbmA7XG5cbmNvbnN0IFdhbGxldERldGFpbHMgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICA+ICoge1xuICAgIG1hcmdpbi1yaWdodDogMXJlbTtcbiAgfVxuICA+ICogKyAqIHtcbiAgICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHdoaXRlO1xuICAgIHBhZGRpbmctbGVmdDogMXJlbTtcbiAgfVxuYDtcblxuaW50ZXJmYWNlIFNlbGVjdGVkV2FsbGV0UHJvcHMge1xuICBzdHlsZT86IENTU1Byb3BlcnRpZXM7XG4gIGNsYXNzTmFtZT86IHN0cmluZztcbn1cblxuY29uc3QgU2VsZWN0ZWRXYWxsZXQ6IFJlYWN0LkZDPFNlbGVjdGVkV2FsbGV0UHJvcHM+ID0gKHtcbiAgc3R5bGUsXG4gIGNsYXNzTmFtZSxcbn0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8QmFzZSBzdHlsZT17c3R5bGV9IGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgIDxJbmZvU2VjdGlvblRpdGxlPlxuICAgICAgICA8Rm9ybWF0dGVkTWVzc2FnZSBpZD1cIndhbGxldC5zZWxlY3RlZFdhbGxldFwiIC8+XG4gICAgICA8L0luZm9TZWN0aW9uVGl0bGU+XG4gICAgICA8V2FsbGV0RGV0YWlscz5cbiAgICAgICAgPFdhbGxldEFjY291bnQgLz5cbiAgICAgICAgPFdhbGxldEJhbGFuY2UgLz5cbiAgICAgIDwvV2FsbGV0RGV0YWlscz5cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBTZWxlY3RlZFdhbGxldCB9O1xuIl19 */");

var InfoSectionTitle = _styled("h3", {
  target: "eyv1hij1",
  label: "selected-wallet_InfoSectionTitle"
})(process.env.NODE_ENV === "production" ? {
  name: "1uk1gs8",
  styles: "margin:0;"
} : {
  name: "1uk1gs8",
  styles: "margin:0;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by9zZWxlY3RlZC13YWxsZXQudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVVrQyIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy9jcnlwdG8vc2VsZWN0ZWQtd2FsbGV0LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcbmltcG9ydCBSZWFjdCwgeyBDU1NQcm9wZXJ0aWVzIH0gZnJvbSAncmVhY3QnO1xuXG5pbXBvcnQgeyBGb3JtYXR0ZWRNZXNzYWdlIH0gZnJvbSAnZ2F0c2J5LXBsdWdpbi1pbnRsJztcblxuaW1wb3J0IHsgV2FsbGV0QWNjb3VudCB9IGZyb20gJ0AvY29tbW9uL2NvbXBvbmVudHMvY3J5cHRvL3dhbGxldC1hY2NvdW50JztcbmltcG9ydCB7IFdhbGxldEJhbGFuY2UgfSBmcm9tICdAL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by93YWxsZXQtYmFsYW5jZSc7XG5cbmNvbnN0IEJhc2UgPSBzdHlsZWQuZGl2YGA7XG5cbmNvbnN0IEluZm9TZWN0aW9uVGl0bGUgPSBzdHlsZWQuaDNgXG4gIG1hcmdpbjogMDtcbmA7XG5cbmNvbnN0IFdhbGxldERldGFpbHMgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICA+ICoge1xuICAgIG1hcmdpbi1yaWdodDogMXJlbTtcbiAgfVxuICA+ICogKyAqIHtcbiAgICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHdoaXRlO1xuICAgIHBhZGRpbmctbGVmdDogMXJlbTtcbiAgfVxuYDtcblxuaW50ZXJmYWNlIFNlbGVjdGVkV2FsbGV0UHJvcHMge1xuICBzdHlsZT86IENTU1Byb3BlcnRpZXM7XG4gIGNsYXNzTmFtZT86IHN0cmluZztcbn1cblxuY29uc3QgU2VsZWN0ZWRXYWxsZXQ6IFJlYWN0LkZDPFNlbGVjdGVkV2FsbGV0UHJvcHM+ID0gKHtcbiAgc3R5bGUsXG4gIGNsYXNzTmFtZSxcbn0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8QmFzZSBzdHlsZT17c3R5bGV9IGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgIDxJbmZvU2VjdGlvblRpdGxlPlxuICAgICAgICA8Rm9ybWF0dGVkTWVzc2FnZSBpZD1cIndhbGxldC5zZWxlY3RlZFdhbGxldFwiIC8+XG4gICAgICA8L0luZm9TZWN0aW9uVGl0bGU+XG4gICAgICA8V2FsbGV0RGV0YWlscz5cbiAgICAgICAgPFdhbGxldEFjY291bnQgLz5cbiAgICAgICAgPFdhbGxldEJhbGFuY2UgLz5cbiAgICAgIDwvV2FsbGV0RGV0YWlscz5cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBTZWxlY3RlZFdhbGxldCB9O1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var WalletDetails = _styled("div", {
  target: "eyv1hij2",
  label: "selected-wallet_WalletDetails"
})(process.env.NODE_ENV === "production" ? {
  name: "1vhrzgb",
  styles: "display:flex;> *{margin-right:1rem;}> * + *{border-left:1px solid white;padding-left:1rem;}"
} : {
  name: "1vhrzgb",
  styles: "display:flex;> *{margin-right:1rem;}> * + *{border-left:1px solid white;padding-left:1rem;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by9zZWxlY3RlZC13YWxsZXQudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWNnQyIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy9jcnlwdG8vc2VsZWN0ZWQtd2FsbGV0LnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcbmltcG9ydCBSZWFjdCwgeyBDU1NQcm9wZXJ0aWVzIH0gZnJvbSAncmVhY3QnO1xuXG5pbXBvcnQgeyBGb3JtYXR0ZWRNZXNzYWdlIH0gZnJvbSAnZ2F0c2J5LXBsdWdpbi1pbnRsJztcblxuaW1wb3J0IHsgV2FsbGV0QWNjb3VudCB9IGZyb20gJ0AvY29tbW9uL2NvbXBvbmVudHMvY3J5cHRvL3dhbGxldC1hY2NvdW50JztcbmltcG9ydCB7IFdhbGxldEJhbGFuY2UgfSBmcm9tICdAL2NvbW1vbi9jb21wb25lbnRzL2NyeXB0by93YWxsZXQtYmFsYW5jZSc7XG5cbmNvbnN0IEJhc2UgPSBzdHlsZWQuZGl2YGA7XG5cbmNvbnN0IEluZm9TZWN0aW9uVGl0bGUgPSBzdHlsZWQuaDNgXG4gIG1hcmdpbjogMDtcbmA7XG5cbmNvbnN0IFdhbGxldERldGFpbHMgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICA+ICoge1xuICAgIG1hcmdpbi1yaWdodDogMXJlbTtcbiAgfVxuICA+ICogKyAqIHtcbiAgICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHdoaXRlO1xuICAgIHBhZGRpbmctbGVmdDogMXJlbTtcbiAgfVxuYDtcblxuaW50ZXJmYWNlIFNlbGVjdGVkV2FsbGV0UHJvcHMge1xuICBzdHlsZT86IENTU1Byb3BlcnRpZXM7XG4gIGNsYXNzTmFtZT86IHN0cmluZztcbn1cblxuY29uc3QgU2VsZWN0ZWRXYWxsZXQ6IFJlYWN0LkZDPFNlbGVjdGVkV2FsbGV0UHJvcHM+ID0gKHtcbiAgc3R5bGUsXG4gIGNsYXNzTmFtZSxcbn0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8QmFzZSBzdHlsZT17c3R5bGV9IGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgIDxJbmZvU2VjdGlvblRpdGxlPlxuICAgICAgICA8Rm9ybWF0dGVkTWVzc2FnZSBpZD1cIndhbGxldC5zZWxlY3RlZFdhbGxldFwiIC8+XG4gICAgICA8L0luZm9TZWN0aW9uVGl0bGU+XG4gICAgICA8V2FsbGV0RGV0YWlscz5cbiAgICAgICAgPFdhbGxldEFjY291bnQgLz5cbiAgICAgICAgPFdhbGxldEJhbGFuY2UgLz5cbiAgICAgIDwvV2FsbGV0RGV0YWlscz5cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBTZWxlY3RlZFdhbGxldCB9O1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var SelectedWallet = function SelectedWallet(_ref) {
  var style = _ref.style,
      className = _ref.className;
  return ___EmotionJSX(Base, {
    style: style,
    className: className
  }, ___EmotionJSX(InfoSectionTitle, null, ___EmotionJSX(FormattedMessage, {
    id: "wallet.selectedWallet"
  })), ___EmotionJSX(WalletDetails, null, ___EmotionJSX(WalletAccount, null), ___EmotionJSX(WalletBalance, null)));
};

SelectedWallet.displayName = "SelectedWallet";
export { SelectedWallet };