import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject;

import gql from 'graphql-tag';
import { useCallback, useEffect } from 'react';
import useSearchParam from "react-use/esm/useSearchParam";
import { useCheckout } from '@/checkout/components/hooks/checkout-hook';
import { useAuthenticatedLazyQuery, useUserError } from '@/common/hooks';
import { CheckoutSessionState } from '@/common/utils/api-types/checkout';
var checkoutSessionQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query CheckoutSessionQuery($sessionId: ID!) {\n    checkoutSession(id: $sessionId) {\n      ... on CheckoutSession {\n        id\n        checkout {\n          id\n          state\n        }\n      }\n    }\n  }\n"])));

function useCheckoutSession() {
  var getSessionId = useSearchParam('sessionId') || '';

  var _useUserError = useUserError(),
      showApolloErrors = _useUserError.showApolloErrors;

  var _useCheckout = useCheckout(),
      checkoutProcess = _useCheckout.checkoutProcess,
      checkoutComplete = _useCheckout.checkoutComplete,
      setCheckoutProcess = _useCheckout.setCheckoutProcess,
      setCheckoutComplete = _useCheckout.setCheckoutComplete;

  var _useAuthenticatedLazy = useAuthenticatedLazyQuery(checkoutSessionQuery, {
    variables: {
      sessionId: getSessionId
    },
    pollInterval: !checkoutProcess || checkoutComplete ? 0 : 1500
  }),
      fetchCheckoutSessionQuery = _useAuthenticatedLazy[0],
      _useAuthenticatedLazy2 = _useAuthenticatedLazy[1],
      checkoutSessionData = _useAuthenticatedLazy2.data,
      checkoutSessionError = _useAuthenticatedLazy2.error;

  var fetchCheckoutSession = useCallback(function () {
    if (getSessionId) {
      setCheckoutProcess(true);
      fetchCheckoutSessionQuery();
    }
  }, [fetchCheckoutSessionQuery, getSessionId, setCheckoutProcess]);
  useEffect(function () {
    var _checkoutSessionData$;

    if (checkoutSessionError) {
      setCheckoutProcess(false);
      showApolloErrors(checkoutSessionError);
      return;
    }

    if ((checkoutSessionData === null || checkoutSessionData === void 0 ? void 0 : (_checkoutSessionData$ = checkoutSessionData.checkoutSession) === null || _checkoutSessionData$ === void 0 ? void 0 : _checkoutSessionData$.checkout.state) === CheckoutSessionState.COMPLETED) {
      setCheckoutProcess(false);
      setCheckoutComplete(true);
    }
  }, [checkoutSessionData, checkoutSessionError, setCheckoutComplete, setCheckoutProcess, showApolloErrors]);
  return {
    fetchCheckoutSession: fetchCheckoutSession,
    checkoutSessionData: checkoutSessionData,
    checkoutSessionError: checkoutSessionError
  };
}

export { useCheckoutSession };