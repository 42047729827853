import _isNumber from "lodash-es/isNumber";
import { useEffect, useState } from 'react';
import useInterval from "react-use/esm/useInterval";

function useCryptoTime(countToTime) {
  var _useState = useState(null),
      timeRemaining = _useState[0],
      setTimeRemaining = _useState[1];

  var _useState2 = useState(true),
      isRunning = _useState2[0],
      setIsRunning = _useState2[1];

  useInterval(function () {
    setTimeRemaining(countToTime - Date.now());
  }, isRunning ? 1000 : null);
  useEffect(function () {
    if (_isNumber(timeRemaining) && timeRemaining <= 0) {
      setIsRunning(false);
    }
  }, [timeRemaining]);
  return {
    timeRemaining: timeRemaining
  };
}

export { useCryptoTime };